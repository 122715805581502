import axios from "axios";
import { toast } from "react-toastify";

import { getAccessToken, getTokens } from "src/utils/token";
import { vectorDBConfig } from "src/config";
import { storeAccessTokenFromResponse } from "src/utils/storeAccessTokenFromResponse";

class SubscriptionApi {
  async fetchAllActivePlans() {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.get(`${baseUrl}/subscriptions/active`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async claimFreeCredits(token?: string) {
    let accessToken, refreshToken;
    if (!token) {
      accessToken = getTokens().accessToken;
      refreshToken = getTokens().refreshToken;
    }
    try {
      const activePlans = await this.fetchAllActivePlans();

      const freePlan = activePlans?.find((plan) => plan.type === "FREE");
      const { baseUrl } = vectorDBConfig();
      const body = { productId: freePlan.id };
      const response = await axios.post(`${baseUrl}/quota/claim-free`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async buyPaidSubscription(body: any) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/quota/claim-paid`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        toast.success("Credits purchased successfully!");
        return response.data;
      } else {
        toast.error("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchSubscriptionDetails(id) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.get(
        `${baseUrl}/subscriptions/detail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "x-refresh-token": refreshToken,
          },
        }
      );

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const subscriptionApi = new SubscriptionApi();
