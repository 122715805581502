import { useState, type FC } from "react";

import * as Yup from "yup";
import { Formik } from "formik";
import { LoginSocialLinkedin, IResolveParams } from "reactjs-social-login";
import { useGoogleLogin } from "@react-oauth/google";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  Divider,
} from "@mui/material";

import useAuth from "src/hooks/useAuth";
import useMounted from "src/hooks/useMounted";

import { ssoConfig } from "src/config";

import linkedin from "src/assets/linkedin.svg";
import google from "src/assets/google.svg";

interface SocialLoginButtonProps {
  isLoading: boolean;
  imgAlt: string;
  imgSrc: string;
  text: string;
  onClick?: () => void;
}

const SocialLoginButton: FC<SocialLoginButtonProps> = (props) => {
  const { isLoading, imgAlt, imgSrc, text, onClick } = props;
  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      variant="outlined"
      sx={(theme) => ({
        padding: "10px",
        width: "100%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.23)"
            : "1px solid rgba(0, 0, 0, 0.23)"
        } `,
      })}
    >
      <img
        style={{ width: "34px", cursor: "pointer" }}
        alt={imgAlt}
        src={imgSrc}
      />
      <Typography
        variant="body2"
        sx={(theme) => ({
          margin: "0 10px",
          color: theme.palette.text.primary,
        })}
      >
        {text}
      </Typography>
    </Button>
  );
};
const LoginJWT: FC = (props) => {
  const mounted = useMounted();
  const [isGoogleSocialLoginLoading, setIsGoogleSocialLoginLoading] =
    useState(false);
  const [isLinkedinSocialLoginLoading, setIsLinkedinSocialLoginLoading] =
    useState(false);

  const auth = useAuth() as any;
  const { login, error, loginSSO } = auth;
  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await loginSSO(codeResponse, "google", setIsGoogleSocialLoginLoading);
    },
    flow: "auth-code",
  });
  const {
    linkedinClientId,
    linkedinClientSecret,
    redirectUri,
  } = ssoConfig();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Box style={{ display: "flex", flexDirection: "column", gap: 15 }}>
            {isGoogleSocialLoginLoading ? (
              <SocialLoginButton
                isLoading={isGoogleSocialLoginLoading}
                imgAlt="googlLogo"
                imgSrc={google}
                text="Signing in..."
                onClick={loginGoogle}
              />
            ) : (
              <SocialLoginButton
                isLoading={isGoogleSocialLoginLoading}
                imgAlt="googlLogo"
                imgSrc={google}
                text="Continue with Google"
                onClick={loginGoogle}
              />
            )}

            {isLinkedinSocialLoginLoading ? (
              <SocialLoginButton
                isLoading={isLinkedinSocialLoginLoading}
                imgAlt="linkedinLogo"
                imgSrc={linkedin}
                text="Signing in..."
              />
            ) : (
              <LoginSocialLinkedin
                client_id={linkedinClientId}
                client_secret={linkedinClientSecret}
                scope={"r_emailaddress+r_liteprofile"}
                redirect_uri={`${redirectUri}/dashboard`}
                onResolve={async ({ provider, data }: IResolveParams) => {
                  await loginSSO(
                    data,
                    provider,
                    setIsLinkedinSocialLoginLoading
                  );
                }}
                onReject={(err: any) => {
                  console.log(err);
                }}
              >
                <SocialLoginButton
                  isLoading={isLinkedinSocialLoginLoading}
                  imgAlt="linkedinLogo"
                  imgSrc={linkedin}
                  text="Continue with Linkedin"
                />
              </LoginSocialLinkedin>
            )}
          </Box>

          <Divider sx={{ my: 5 }} textAlign="center">
            OR
          </Divider>

          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={
              touched.email && errors.email
                ? touched.email && errors.email
                : ` `
            }
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={(e) => !e.relatedTarget && handleBlur(e)}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            style={{ margin: "0" }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={
              touched.password && errors.password
                ? touched.password && errors.password
                : ` `
            }
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            style={{ margin: "10px 0 0" }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{`${errors.submit}`}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>

          {error?.message && (
            <Box sx={{ mt: 2 }}>
              <Alert severity="error">{error.message}</Alert>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
