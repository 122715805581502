import { useEffect, type FC } from "react";

import { Autocomplete, Box, Button, Container, Paper, TextField } from "@mui/material";

import DialogLayout from "../DialogLayout";
import { useNavigate } from "react-router";

const CreateEmailAgentModal: FC<any> = (props) => {
  const {
    handleGmailIngestorStatus,
    createEmailAgentModal,
    setCreateEmailAgentModal,
    url,
    handleEmailBotInput,
    emailBotInput,
    submitClicked,
    setSubmitClicked,
    vectorIndexOptions,
    handleCreateEmailAgent,
    loadingStates,
    handleClose
  } = props;

  return (
    <DialogLayout
      isOpen={createEmailAgentModal}
      onClose={handleClose}
      title="Create New Email Agent"
      action="Submit"
      handleAction={() => {
        setSubmitClicked(true);
        if (
          emailBotInput.title &&
          emailBotInput.email &&
          emailBotInput.vectorIndexId
        ) {
          handleCreateEmailAgent();
        }
      }}
      isLoading={loadingStates.createEmailAgent}
    >
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <Box>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            sx={{
              width: "100%",
            }}
            value={emailBotInput.email}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            sx={{
              width: "100%",
            }}
            onChange={(e) => handleEmailBotInput("title", e.target.value)}
            error={submitClicked && !emailBotInput.title}
            helperText={
              submitClicked && !emailBotInput.title
                ? "Email agent title should not be empty"
                : ""
            }
          />
        </Box>
        <Box>
        <Autocomplete
        options={vectorIndexOptions}
        getOptionLabel={(option: any) => option.label}
        value={
          vectorIndexOptions.find(
            (option) => option.id === emailBotInput.vectorIndexId
          ) || null
        }
        onChange={(event, newValue) => {
          if (newValue) {
            handleEmailBotInput("vectorIndexId", newValue.id);
          }
        }}
        clearIcon={null}
        noOptionsText="No vector indexes available"
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
            })}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Select Vector Index"
            sx={{
              width: "100%",
              mb: 3,
            }}
            error={submitClicked && !emailBotInput.vectorIndexId}
            helperText={
              submitClicked && !emailBotInput.vectorIndexId
                ? "You must select a vector index."
                : ""
            }
          />
        )}
      />
        </Box>
       
      </Container>
    </DialogLayout>
  );
};

export default CreateEmailAgentModal;
