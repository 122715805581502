import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";

import {
  Grid,
  Link,
  Container,
  Typography,
  Breadcrumbs,
  Box,
  Button,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import ChevronRightIcon from "src/icons/ChevronRight";
import useEmailAgent from "src/hooks/useEmailAgent";
import TabsEmailAgents from "src/components/dashboard/emailAgents/TabsEmailAgents";
import CreateEmailAgentModal from "src/components/dashboard/emailAgents/CreateEmailAgentModal";

const pageTitle = "Email Agents";

const EmailAgentsPage: React.FC<any> = () => {
  const {
    loadingStates,
    emailAgents,
    handleDeleteModal,
    handleDeleteEmailAgent,
    isDeleteModal,
    setIsDeleteModal,
    handleGmailIngestorStatus,
    createEmailAgentModal,
    setCreateEmailAgentModal,
    url,
    setUrl,
    handleEmailBotInput,
    emailBotInput,
    submitClicked,
    setSubmitClicked,
    vectorIndexOptions,
    handleCreateEmailAgent,
    handleClose,
    handlePublishEmailBot,
    handleUnpublishEmailBot
  } = useEmailAgent();

  return (
    <>
      <Helmet>
        <title>APEXE3 Vector Database - {pageTitle}</title>
      </Helmet>
      <Container sx={{ maxWidth: "none !important" }}>
        <Grid position="relative" sx={{ py: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid item sx={{ paddingLeft: 2 }}>
                <Typography color="textPrimary" variant="h5">
                  {pageTitle}
                </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                >
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    to="/dashboard"
                    variant="subtitle2"
                  >
                    Overview
                  </Link>
                  <Typography color="textPrimary" variant="subtitle2">
                    {pageTitle}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                gap: 30,
              }}
            >
              <Box display="flex" flexDirection="row" justifyContent={"end"}>
                <Button
                  sx={{ marginRight: 2, borderRadius: "20px" }}
                  variant="outlined"
                  onClick={handleGmailIngestorStatus}
                  disabled={loadingStates.validateToken}
                  // startIcon={<AddCircleRoundedIcon fontSize="small" />}
                >
                  Configure New Account
                </Button>
                {/* <Button
                  sx={{ marginRight: 2, borderRadius: "20px" }}
                  variant="outlined"
                  onClick={() => setCreateEmailAgentModal(true)}
                  // startIcon={<AddCircleRoundedIcon fontSize="small" />}
                >
                  Create New Email Agent
                </Button> */}
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <TabsEmailAgents
              loadingStates={loadingStates}
              emailAgents={emailAgents}
              handleDeleteModal={handleDeleteModal}
              handleDeleteEmailAgent={handleDeleteEmailAgent}
              isDeleteModal={isDeleteModal}
              setIsDeleteModal={setIsDeleteModal}
              handlePublishEmailBot={handlePublishEmailBot}
    handleUnpublishEmailBot={handleUnpublishEmailBot}
            />
          </Grid>
        </Grid>
        {createEmailAgentModal && (
          <CreateEmailAgentModal
            handleGmailIngestorStatus={handleGmailIngestorStatus}
            createEmailAgentModal={createEmailAgentModal}
            setCreateEmailAgentModal={setCreateEmailAgentModal}
            url={url}
            handleEmailBotInput={handleEmailBotInput}
            emailBotInput={emailBotInput}
            submitClicked={submitClicked}
            setSubmitClicked={setSubmitClicked}
            vectorIndexOptions={vectorIndexOptions}
            handleCreateEmailAgent={handleCreateEmailAgent}
            loadingStates={loadingStates}
            handleClose={handleClose}
          />
        )}
      </Container>
    </>
  );
};

export default EmailAgentsPage;
