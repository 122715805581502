export const ssoConfig = () => {
  return {
    googleClientId: (window as any).REACT_APP_GOOGLE_CLIENT_ID || process.env.REACT_APP_GOOGLE_CLIENT_ID,
    linkedinClientId: (window as any).REACT_APP_LINKEDIN_CLIENT_ID || process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    linkedinClientSecret: (window as any).REACT_APP_LINKEDIN_CLIENT_SECRET || process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
    redirectUri: (window as any).REACT_APP_REDIRECT_URI || process.env.REACT_APP_REDIRECT_URI,
  };
};

export const authenticationConfig = () => {
  return {
    baseUrl: (window as any).REACT_APP_AUTHENTICATION_BASE_URL || process.env.REACT_APP_AUTHENTICATION_BASE_URL,
  };
};

export const vectorDBConfig = () => {
  return {
    baseUrl: (window as any).REACT_APP_VECTOR_DB_BASE_URL || process.env.REACT_APP_VECTOR_DB_BASE_URL,
  };
};

export const botEngineConfig = () => {
  return{
    baseUrl: (window as any).REACT_APP_BOT_ENGINE_BASE_URL || process.env.REACT_APP_BOT_ENGINE_BASE_URL,
  }
}

export const botWidgetConfig = () => {
  return{
    baseUrl: (window as any).REACT_APP_BOT_WIDGET || process.env.REACT_APP_BOT_WIDGET,
  }
}

export const emailEngineConfig = () => {
  return{
    baseUrl: (window as any).REACT_APP_EMAIL_BOT_ENGINE || process.env.REACT_APP_EMAIL_BOT_ENGINE,
  }
}

export const emailIngestorConfig = () => {
  return{
    emailIngestorUrl: (window as any).REACT_APP_EMAIL_INGESTOR || process.env.REACT_APP_EMAIL_INGESTOR,
  }
}