import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "src/store";
import {
  fetchEmailAgents,
  gmailIngestorStatus,
  validateToken,
  createEmailAgent,
  deleteEmailAgent,
  publishEmailAgent,
  unpublishEmailAgent,
} from "src/store/emailAgent/action";
import { fetchAllVectorIndexes } from "src/store/vectorIndexes/actions";

const useEmailAgent = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const { data: emailAgents = {} } = useSelector(
    (state: any) => state?.emailAgents?.EmailAgentsReducer
  );

  const { data: vectorIndexes = [] } = useSelector(
    (state: any) => state?.vectorIndexes?.VectorIndexeReducer
  );

  const [loadingStates, setLoadingStates] = useState({
    agentsLoading: false,
    deleteEmailAgent: false,
    fetchEmailAgents: false,
    validateToken: false,
    fetchIndexes: false,
    createEmailAgent: false,
  });
  const [url, setUrl] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [emailBotInput, setEmailBotInput] = useState({
    email: "",
    title: "",
    vectorIndexId: "",
  });
  const [selectedEmailAgentId, setSelectedEmailAgentId] = useState(null);

  const handleEmailBotInput = (key: string, value: string) => {
    setEmailBotInput({
      ...emailBotInput,
      [key]: value,
    });
  };

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [createEmailAgentModal, setCreateEmailAgentModal] = useState(false);

  const vectorIndexOptions =
    vectorIndexes?.data?.map((index: any) => ({
      label: index.name,
      id: index.id,
    })) || [];

  const handleLoadingStates = (key: string, value: boolean) => {
    setLoadingStates({
      ...loadingStates,
      [key]: value,
    });
  };

  const handleDeleteEmailAgent = async() => {
    await dispatch(deleteEmailAgent(selectedEmailAgentId, handleLoadingStates));
    setIsDeleteModal(false);
    setSelectedEmailAgentId(null);
    await dispatch(fetchEmailAgents(handleLoadingStates));

  }

  const handleDeleteModal = (id: string) => {
    setSelectedEmailAgentId(id);
    setIsDeleteModal(true);
  };

  const dispatch = useDispatch();

  const handleGmailIngestorStatus = async () => {
    const response = await dispatch(gmailIngestorStatus(handleLoadingStates));
    console.log("response", response);

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (urlRegex.test(response)) {
      setUrl(response);
      console.log("The response is a link:", response);
    } else {
      console.log("The response is not a link.");
    }
  };


  const handleValidateToken = async (code: string) => {
    handleLoadingStates("validateToken", true);
    const data = await dispatch(
      validateToken(handleLoadingStates, code, navigate)
    );
    if (data) {
      handleEmailBotInput("email", data);
      setCreateEmailAgentModal(true);
    }
  };

  const handlePublishEmailBot = async (id: string) => {
    await dispatch(publishEmailAgent(id))
    await dispatch(fetchEmailAgents(handleLoadingStates));
    };

    const handleUnpublishEmailBot = async (id: string) => {
      await dispatch(unpublishEmailAgent(id))
      await dispatch(fetchEmailAgents(handleLoadingStates));
      };

  const handleCreateEmailAgent = async () => {
    await dispatch(
      createEmailAgent(
        handleLoadingStates,
        emailBotInput,
        setCreateEmailAgentModal
      )
    );
    setEmailBotInput({
      email: "",
      title: "",
      vectorIndexId: "",
    });
    await dispatch(fetchEmailAgents(handleLoadingStates));
  };
  const handleClose = () => {
    setCreateEmailAgentModal(false);
    setEmailBotInput({
      email: "",
      title: "",
      vectorIndexId: "",
    });
    // navigate('/dashboard/email-agents')
  };

  useEffect(() => {
    let code = queryParams.get("code");
    if (code) {
      // setTimeout(() => {
      handleValidateToken(code);
      // }, 2000);
    }

    const fetchIndexBody = {
      sortBy: "asc",
    };
    dispatch(
      fetchAllVectorIndexes(fetchIndexBody, (isTrue) =>
        handleLoadingStates("fetchIndexes", isTrue)
      )
    );
  }, []);

  useEffect(() => {
    dispatch(fetchEmailAgents(handleLoadingStates));
  }, [dispatch]);

  useEffect(() => {
    if (url) {
      window.open(url, "_blank");
      setUrl(null);
    }
  }, [url]);

  return {
    loadingStates,
    emailAgents,
    handleDeleteModal,
    handleDeleteEmailAgent,
    isDeleteModal,
    setIsDeleteModal,
    handleGmailIngestorStatus,
    createEmailAgentModal,
    setCreateEmailAgentModal,
    url,
    setUrl,
    handleEmailBotInput,
    emailBotInput,
    submitClicked,
    setSubmitClicked,
    vectorIndexOptions,
    handleCreateEmailAgent,
    handleClose,
    handlePublishEmailBot,
    handleUnpublishEmailBot
  };
};

export default useEmailAgent;
