import axios from "axios";

import { User } from "../types/user";
import { authenticationConfig } from "src/config";
import { entityServiceAPI } from "./entityServiceApi";

import wait from "../utils/wait";
import createResourceId from "../utils/createResourceId";
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from "../utils/jwt";

const users = [];

class AuthApi {
  async login({ email, password }): Promise<string> {
    await wait(500);

    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();

        let formData = {
          email: email,
          password: password,
        };

        let url = `${baseUrl}/auth/login`;
        let res: any = await axios.post(url, formData, {
          headers: {
            "content-type": "application/json",
            provider: "vector",
          },
        });

        // console.log("RESULT FROM KLOGIN: ", res);
        if (res != null && res.data != null && res.data.access_token != null) {
          resolve(res.data);
        } else {
          reject(new Error("Please check your email and password"));
          return;
        }
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Please check email and password"));
      }
    });
  }

  async loginBK({ email, password }): Promise<string> {
    await wait(500);

    return new Promise((resolve, reject) => {
      try {
        // Find the user
        const user = users.find((_user) => _user.email === email);

        if (!user || user.password !== password) {
          reject(new Error("Please check your email and password"));
          return;
        }

        // Create the access token
        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve(accessToken);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async registerKC({ email, firstname, lastname, password }): Promise<string> {
    await wait(1000);

    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();

        let formData = {
          firstName: firstname,
          lastName: lastname,
          email: email,
          username: email,
          password: password,
        };

        let url = `${baseUrl}/auth/register`;

        let res: any = await axios.post(url, formData, {
          headers: {
            "content-type": "application/json",
            provider: "alice",
          },
        });

        // console.log("RESULT FROM REGISTER: ", res);
        if (res != null && res?.status != null) {
          resolve(res?.status);
        } else {
          reject(new Error("Error in registration. Please check your details"));
          return;
        }
      } catch (err) {
        if (err?.response?.status === 500 || err?.response?.status === "500") {
          reject(new Error("Error in registration. Please check your details"));
        } else {
          reject(new Error(err?.response?.data?.message));
        }
        console.error("[Auth Api]: ", err);
      }
    });
  }

  async loginSSO({ data, provider }): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();

        let formData = {};
        if (provider === "linkedin") {
          formData = {
            token: data?.access_token,
            username: data?.localizedFirstName + data?.localizedLastName,
            firstName: data?.localizedFirstName,
            lastName: data?.localizedLastName,
            expires_in: data?.expires_in,
            avatar: "",
            id: data?.id,
            provider,
          };
        } else if (provider === "google") {
          formData = {
            code: data?.code,
            provider,
          };
        }

        let url = `${baseUrl}/auth/login/sso`;
        let res: any = await axios.post(url, formData, {
          headers: {
            "content-type": "application/json",
            provider: `${provider}`,
          },
        });

        // console.log("RESULT FROM KLOGIN: ", res);
        if (res != null && res.data != null && res.data.access_token != null) {
          resolve(res.data);
        } else {
          reject(new Error("Please check your email and password"));
          return;
        }
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Something went wrong, Try again"));
      }
    });
  }

  async register({ email, name, password }): Promise<string> {
    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        let user = users.find((_user) => _user.email === email);

        if (user) {
          reject(new Error("User already exists"));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: null,
          email,
          name,
          password,
          plan: "Standard",
        };

        users.push(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve(accessToken);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  me(accessToken): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        // Decode access token
        const { userId } = decode(accessToken) as any;

        // Find the user
        const user = users.find((_user) => _user.id === userId);

        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        const userProfile = await entityServiceAPI.fetchUserProfile();

        let userProfileEnriched = {
          id: userProfile.id,
          avatar: userProfile.imageLink,
          email: user.email,
          name: user.name,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          username: userProfile.username,
        };
        resolve(userProfileEnriched);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  resolveUser(accessToken): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        const userProfile = await entityServiceAPI.fetchUserProfile();

        let user = {
          id: userProfile.id,
          avatar: userProfile.imageLink,
          email: userProfile.email,
          name: userProfile.name,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          username: userProfile.username,
        };
        resolve(user);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Could not resolve user"));
      }
    });
  }

  async logout(): Promise<string> {
    const refreshToken = JSON.parse(
      window.localStorage.getItem("refreshToken")
    );
    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();

        const url = `${baseUrl}/users/logout`;
        let formData = {
          refresh_token: refreshToken?.token,
        };

        let res: any = await axios.post(url, formData, {
          headers: {
            "content-type": "application/json",
            provider: "vector",
          },
        });

        if (res != null && res.status != null) {
          resolve(res.status);
        } else {
          reject(new Error("Error in logout. Please check your details"));
          return;
        }
      } catch (err) {
        console.error("[Auth Api]: ", err);
        new Error("Error in Logout. Please check your details");
      }
    });
  }
  
  async passwordRecovery(email: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();
        const url = `${baseUrl}/auth/request/password-reset`;
        const formData = {
          email: email,
        };
        const res: any = await axios.post(url, formData);

        if (res.status === 201) {
          resolve(res.status);
        } else {
          reject(new Error(""));
          return;
        }
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Could not change password"));
      }
    });
  }

  async passwordReset(
    email: string,
    code: string,
    newPassword: string
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();
        const url = `${baseUrl}/auth/password-reset`;
        const formData = {
          email: email,
          password: newPassword,
          resetToken: code,
        };
        const res: any = await axios.post(url, formData);

        if (res.status === 201) {
          resolve("Email sent");
        } else {
          reject(new Error(""));
          return;
        }
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Could not reset password"));
      }
    });
  }

  async refreshToken(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const { baseUrl } = authenticationConfig();
        const url = `${baseUrl}/users/tokens/refresh`;
        const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
        const formData = {
          refresh_token: refreshToken.token,
        };

        let res: any = await axios.post(url, formData, {
          headers: {
            "content-type": "application/json",
            provider: "alice",
          },
        });

        if (res != null && res.status != null) {
          resolve(res.data);
        } else {
          reject(new Error("Error in fetching new token"));
          return;
        }
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Could not fetch new token"));
      }
    });
  }
}

export const authApi = new AuthApi();
