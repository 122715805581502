import type { FC } from "react";

import { Box, TableCell } from "@mui/material";

const ActionColumn: FC<any> = (props) => {
  const { actions } = props;
  return (
    <TableCell
      sx={(theme) => ({
        position: "sticky",
        right: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: 1,
      })}
    >
      <Box
        sx={{
          width: "150px",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {actions?.map((action, key) => (
          <Box
            key={key}
            sx={{ fontSize: "14px", cursor: "pointer" }}
            onClick={action?.handleClick}
          >
            {action?.icon}
          </Box>
        ))}
      </Box>
    </TableCell>
  );
};

export default ActionColumn;
