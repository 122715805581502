import { isString } from "lodash";
import { toast } from "react-toastify";

import { emailAgentApi } from "src/__Api__/emailAgentApi";

import {
  FETCH_EMAIL_AGENTS_END,
  FETCH_EMAIL_AGENTS_RECIEVES,
  FETCH_EMAIL_AGENTS_START,
} from "./action-types";


export const gmailIngestorStatus =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      const result = await emailAgentApi.gmailIngestorStatus();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

export const validateToken =
  (handleLoadingStates: any, code: string, navigate: any) => async (dispatch) => {
    try {
      const response = await emailAgentApi.validateToken(code);
      handleLoadingStates("validateToken", false);
      navigate('/dashboard/email-agents')
      if (response) {
        const { message, email } = response;
        if(email){
          toast.success(message);
        }
        else{
          toast.error(message);
        }
        return email;
      }
    } catch (error) {
      console.log(error);
      handleLoadingStates("validateToken", false);
    }
  };

export const fetchEmailAgents =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_EMAIL_AGENTS_START });
      handleLoadingStates("fetchEmailAgents", true);
      const result = await emailAgentApi.getAllEmailAgents();
      handleLoadingStates("fetchEmailAgents", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_EMAIL_AGENTS_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_EMAIL_AGENTS_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      handleLoadingStates("fetchEmailAgents", false);
      dispatch({
        type: FETCH_EMAIL_AGENTS_END,
        data: [],
        error,
      });
    }
  };

export const createEmailAgent =
  (handleLoadingStates: any, body: any, setCreateEmailAgentModal: any) =>
  async (dispatch) => {
    try {
      handleLoadingStates("createEmailAgent", true);
      const result = await emailAgentApi.createEmailAgent(body);
      handleLoadingStates("createEmailAgent", false);
      if (!isString(result)) {
        setCreateEmailAgentModal(false);
        toast.success("Email agent created successfully");
      }
    } catch (error) {
      handleLoadingStates("createEmailAgent", true);
      console.log(error);
    }
  };

export const deleteEmailAgent =
  (id: string, handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("deleteEmailAgent", true);
      const result = await emailAgentApi.deleteEmailAgent(id);
      handleLoadingStates("deleteEmailAgent", false);
      // if (isString(result)) {
      //   toast.success(result);
      // }
    } catch (error) {
      handleLoadingStates("deleteEmailAgent", false);
      console.log(error);
    }
  };

  export const publishEmailAgent =
  (id: string) => async (dispatch) => {
    try {
      const result = await emailAgentApi.publishEmailAgent(id);
      if (isString(result)) {
        toast.success(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  export const unpublishEmailAgent =
  (id: string) => async (dispatch) => {
    try {
      const result = await emailAgentApi.unpublishEmailAgent(id);
      if (isString(result)) {
        toast.success(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
