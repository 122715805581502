import React from "react";

import {
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  CircularProgress,
  Switch,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { alpha, styled } from "@mui/material/styles";


import DeleteDialog from "../DeleteDialog";
import ActionColumn from "../ActionColumn";

import useSettings from "src/hooks/useSettings";

import Trash from "src/icons/Trash";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#76d14d",
    "&:hover": {
      backgroundColor: alpha("#76d14d", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#76d14d",
  },
}));

const TableEmailAgents: React.FC<any> = (props) => {
  const { settings } = useSettings();
  const {
    loadingStates,
    emailAgents,
    handleDeleteModal,
    handleDeleteEmailAgent,
    isDeleteModal,
    setIsDeleteModal,
    handlePublishEmailBot,
    handleUnpublishEmailBot,

  } = props;
  return (
    <Paper sx={{ border: "none", boxShadow: "none" }}>
      <TableContainer
        sx={(theme) => ({
          minHeight: 440,
          height: "70vh",
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Email Agent Name",
                "Vector Index",
                "Email",
                "Created At",
                "Actions",
              ].map((header, key) => (
                <TableCell
                  key={key}
                  align={
                     key === 4
                      ? "center"
                      : "left"
                  }
                  sx={(theme) => ({
                    color: theme.palette.secondary.contrastText,
                    backgroundColor: theme.palette.secondary.main,
                    position: "sticky",
                    top: 0,
                    right: key === 8 ? 0 : "auto",
                    zIndex: key === 8 ? 2 : 1,
                  })}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!loadingStates.fetchEmailAgents ? (
            emailAgents?.length ? (
                emailAgents?.map((emailAgent: any) => {
                return (
                  <TableBody key={emailAgent?.id}>
                    <TableRow
                      sx={(theme) => ({
                        "&:hover": {
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgba(41, 41, 46, 0.4)"
                              : "rgba(0, 0, 0, 0.04)",
                        },
                        "&:td": {
                          borderBottom: 1,
                        },
                        maxWidth: "100px",
                      })}
                    >

                      <TableCell
                        align="left"
                        style={{ wordWrap: "break-word" }}
                      >
                        {emailAgent?.title}
                      </TableCell>
                      <TableCell align="left">
                        {emailAgent?.vectorIndex}
                      </TableCell>
                      <TableCell align="left">
                        {emailAgent?.email}
                      </TableCell>
                      
                      <TableCell align="left">
                        {emailAgent?.createdAt}
                      </TableCell>

                      <ActionColumn
                        actions={[
                          {
                            handleClick: () => {
                              if (!emailAgent?.isPublished) {
                                handlePublishEmailBot(emailAgent?.id);
                              } else {
                                handleUnpublishEmailBot(emailAgent?.id);
                              }

                            },
                            icon: (
                              <>
                                {!emailAgent?.isPublished ? (
                                  <GreenSwitch />
                                ) : (
                                  <GreenSwitch defaultChecked />
                                )}
                              </>
                            ),
                          },
                          {
                            handleClick: ()=>handleDeleteModal(emailAgent?.id),
                            icon: (
                              <IconButton>
                                <Trash color="error" />
                              </IconButton>
                            ),
                          },
                        ]}
                      />
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    {" "}
                    No Data Found{" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            )
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {" "}
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {isDeleteModal ? (
        <DeleteDialog
          isLoading={loadingStates.deleteEmailAgent}
          isOpen={isDeleteModal}
          setIsDeleteModal={setIsDeleteModal}
          handleDelete={handleDeleteEmailAgent}
          title={"Delete Email Agent"}
        />
      ) : null}
    </Paper>
  );
};

export default TableEmailAgents;
