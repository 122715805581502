import axios from "axios";
import { toast } from "react-toastify";

import { emailEngineConfig, emailIngestorConfig } from "src/config";
import { getAccessToken } from "src/utils/token";

class EmailAgentApi {
  async gmailIngestorStatus() {
    try {
      const { emailIngestorUrl } = emailIngestorConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${emailIngestorUrl}/corprime/ingestor/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async validateToken(code: string) {
    try {
      const { emailIngestorUrl } = emailIngestorConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${emailIngestorUrl}/corprime/ingestor/validateToken?code=${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async getAllEmailAgents() {
    try {
      const {baseUrl} = emailEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${baseUrl}/email-bots`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async createEmailAgent(body: any) {
    try {
      const { baseUrl } = emailEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/email-bots`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async deleteEmailAgent(id: string) {
    try {
      const { baseUrl } = emailEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.delete(`${baseUrl}/email-bots/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("Unable to delete try again");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async publishEmailAgent(id: string) {
    try {
      const { baseUrl } = emailEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/email-bots/publish/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("Unable to publish, try again");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async unpublishEmailAgent(id: string) {
    try {
      const { baseUrl } = emailEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/email-bots/unpublish/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("Unable to unpublish, try again");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const emailAgentApi = new EmailAgentApi();
