import type { FC } from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import DialogLayout from "./DialogLayout";

import { DeleteDialogInterface } from "src/types/dashboard";

const DeleteDialog: FC<DeleteDialogInterface> = (props) => {
  const { isOpen, setIsDeleteModal, handleDelete, title, isLoading } = props;

  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={() => setIsDeleteModal(false)}
      title={title}
      action="Delete"
      handleAction={handleDelete}
      isDeleteDialog={true}
      isLoading={isLoading}
      width="500px"
    >
      <Container>
        <Box sx={{ minHeight: "100%", py: 2 }}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary">
                Do you really want to delete this?
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </DialogLayout>
  );
};

export default DeleteDialog;
