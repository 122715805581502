import axios from "axios";
import { toast } from "react-toastify";

import { CreateBot, FetchBots } from "src/types/bots";
import { botEngineConfig } from "src/config";
import { getAccessToken, getTokens } from "src/utils/token";
import { storeAccessTokenFromResponse } from "src/utils/storeAccessTokenFromResponse";

class BotsApi {
  async fetchAllBots(body: FetchBots) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/bots/all`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchBot(id: string) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.get(`${baseUrl}/bots/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async createBot(body: CreateBot) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/bots`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        toast.success("Agent created successfully.");
        return response.data;
      } else {
        toast.error("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async deleteBot(id: string) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.delete(`${baseUrl}/bots/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async updateBot(id: string, body: CreateBot) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.patch(`${baseUrl}/bots/${id}`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async publishBot(id: string) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/bots/publish/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async unpublishBot(id: string) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/bots/unpublish/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async getAllBotThemes() {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.get(`${baseUrl}/bot-themes/user`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });
      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async uploadImageFile(body: FormData) {
    try {
      const { baseUrl } = botEngineConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/gcp/imageUpload`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "multipart/form-data",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const botsApi = new BotsApi();
