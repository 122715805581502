import { FC } from "react";

import { createCustomTheme } from "./theme";

import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import useSettings from "./hooks/useSettings";
import routes from "./routes";

import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./i18n";

import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App: FC = () => {
  console.log("App");
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer position="top-center" theme={theme.palette.mode} transition= {Slide} />
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
