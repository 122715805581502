import type { FC } from "react";

import { Grid } from "@mui/material";

const TabsVectorLayout: FC<any> = ({ children }) => {
  return (
    <>
      <Grid position="relative" sx={{ py: 4, paddingTop: "inherit" }}>
        {children}
      </Grid>
    </>
  );
};

export default TabsVectorLayout;
