import React from "react";

import { Box, Grid } from "@mui/material";

import TabsVectorLayout from "../TabsVectorLayout";
import TableEmailAgents from "./TableEmailAgents";

const TabsEmailAgents: React.FC<any> = (props) => {
  const {
    loadingStates,
    emailAgents,
    handleDeleteModal,
    handleDeleteEmailAgent,
    isDeleteModal,
    setIsDeleteModal,
    handlePublishEmailBot,
    handleUnpublishEmailBot,
  } = props;
  return (
    <TabsVectorLayout>
      <Grid item>
        <Box sx={{ mt: 5 }}>
          <TableEmailAgents
            loadingStates={loadingStates}
            emailAgents={emailAgents}
            handleDeleteModal={handleDeleteModal}
            handleDeleteEmailAgent={handleDeleteEmailAgent}
            isDeleteModal={isDeleteModal}
            setIsDeleteModal={setIsDeleteModal}
            handlePublishEmailBot={handlePublishEmailBot}
    handleUnpublishEmailBot={handleUnpublishEmailBot}
          />
        </Box>
      </Grid>
    </TabsVectorLayout>
  );
};

export default TabsEmailAgents;
