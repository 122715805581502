import axios from "axios";
import { toast } from "react-toastify";

import { getAccessToken, getTokens } from "src/utils/token";
import { vectorDBConfig } from "src/config";
import {
  SearchIndex,
  VectorIndexes,
  fetchVectorIndexes,
} from "src/types/vectorIndexes";
import { storeAccessTokenFromResponse } from "src/utils/storeAccessTokenFromResponse";

class VectorIndexesApi {
  async fetchAllVectorIndexes(body: fetchVectorIndexes) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/vector-indexes/all`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);

        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchVectorIndex(id: string) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.get(`${baseUrl}/vector-indexes/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchAllModelDimensions() {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.get(
        `${baseUrl}/vector-indexes/model-dimensions`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "x-refresh-token": refreshToken,
          },
        }
      );

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        const filteredData = response?.data?.filter(
          (model) => model.name === "Universal Sentence Encoder"
        );
        return filteredData;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async createVectorIndexes(body: VectorIndexes) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(`${baseUrl}/vector-indexes`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);

        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async deleteVectorIndexe(id: string) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.delete(`${baseUrl}/vector-indexes/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "x-refresh-token": refreshToken,
        },
      });

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response);
        return response.data;
      } else {
        toast.success("Unable to delete try again");
        throw new Error("not deleted");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async searchIndex(body: SearchIndex) {
    try {
      const { baseUrl } = vectorDBConfig();
      const { accessToken, refreshToken } = getTokens();
      const response = await axios.post(
        `${baseUrl}/vector-resources/searchIndex`,
        body,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-refresh-token": refreshToken,
          },
        }
      );

      if (response != null && response.data) {
        storeAccessTokenFromResponse(response)
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const vectorIndexesApi = new VectorIndexesApi();
