// import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import StyledEngineProvider from '@mui/system/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/KeycloakContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ssoConfig } from './config';

const {
  googleClientId,
} = ssoConfig();

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        {/* <StyledEngineProvider injectFirst> */}
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                <GoogleOAuthProvider clientId={googleClientId}>
                  <App />
                  </GoogleOAuthProvider>
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          {/* </LocalizationProvider> */}
        {/* </StyledEngineProvider> */}
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
