import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { styled, Theme, CSSObject } from "@mui/material/styles";
import {
  Box,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import SchemaIcon from "@mui/icons-material/Schema";
import ArticleIcon from "@mui/icons-material/Article";
import PaymentIcon from "@mui/icons-material/Payment";
import HistoryIcon from "@mui/icons-material/History";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import MailIcon from '@mui/icons-material/Mail';

import DashboardNavbar from "./DashboardNavbar";

import useAuth from "src/hooks/useAuth";
import useSettings from "src/hooks/useSettings";

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Client Secret Keys",
        path: "/dashboard/clients",
        icon: <LockRoundedIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__secrets",
      },
      {
        title: "Vector Indexes",
        path: "/dashboard",
        icon: <SchemaIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__indexes",
      },
      {
        title: "Vector Resources",
        path: "/dashboard/vector-resources",
        icon: <ArticleIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__resources",
      },
      {
        title: "Agents",
        path: "/dashboard/bots",
        icon: <SmartToyIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__bots",
      },
      {
        title: "Email Agents",
        path: "/dashboard/email-agents",
        icon: <MailIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__email",
      },
      {
        title: "Payment History",
        path: "/dashboard/payment-history",
        icon: <PaymentIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__payments",
      },
      {
        title: "Quota History",
        path: "/dashboard/quota-history",
        icon: <HistoryIcon fontSize="small" htmlColor={"#0692e2"} />,
        reactTourId: "reactour__quota",
      },
    ],
  },
];

const drawerWidth = 240;

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.background.default,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardSidebar = (props) => {
  const { refreshToken, logoutSSO } = useAuth();
  const location = useLocation();
  const { totalCredits, availableFeatures } = props;
  const { settings } = useSettings();

  React.useEffect(() => {
    const intervalDuration = 28 * 60 * 1000 + 30 * 1000;

    const checkTokenExpiration = () => {
      const sso = localStorage.getItem("sso");
      if (JSON.parse(sso)) {
        const currentTime = new Date().getTime();
        const refresh_token = JSON.parse(
          window.localStorage.getItem("refreshToken")
        );
        const timeExceeds = currentTime * 0.001 > refresh_token?.expiresIn;
        if (timeExceeds) {
          logoutSSO();
        }
      } else {
        const currentTime = new Date().getTime();
        const refresh_token = JSON.parse(
          window.localStorage.getItem("refreshToken")
        );
        const inTime = currentTime * 0.001 + 15 < refresh_token?.expiresIn;
        const timeExceeds = currentTime * 0.001 > refresh_token?.expiresIn;
        if (inTime) {
          refreshToken();
        } else if (timeExceeds) {
          logoutSSO();
          // logout();
        }
      }
      setTimeout(() => checkTokenExpiration(), intervalDuration);
    };
    checkTokenExpiration();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <DashboardNavbar
        open={open}
        handleDrawer={handleDrawer}
        totalCredits={totalCredits}
        availableFeatures={availableFeatures}
      />

      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            borderRight:
              settings?.theme === "DARK"
                ? "1px solid rgb(255, 255, 255, 10%) !important"
                : "1px solid rgb(0, 0, 0, 10%) !important",
          },
        }}
      >
        <Box
          sx={{
            my: "15px",
            marginLeft: "18px",
            width: `${open ? "100%" : "25px"}`,
            overflow: "hidden",
            borderRight: "1px solid rgb(0, 0, 0, 10%) !important",
          }}
        >
          <img
            alt="Apex:E3 Logo"
            height="28"
            src={
              settings?.theme !== "DARK"
                ? "/static/Apex_Logo_2022_large.png"
                : "/static/Apex_Logo_2022_Large 2.png"
            }
          />
        </Box>

        <Divider
          style={{
            marginBottom: "20px",
            backgroundColor:
              settings?.theme === "DARK"
                ? "rgb(255, 255, 255, 5%)"
                : "rgb(0, 0, 0, 10%)",
          }}
        />
        <List>
          {sections[0]?.items?.map((item, index) => (
            <StyledRouterLink
              key={index}
              to={item?.path}
              // style={{ textDecoration: "none", color: "white" }}
            >
              <ListItem
                key={index}
                disablePadding
                sx={(theme) => ({
                  display: "block",
                  backgroundColor:
                    location.pathname === item?.path
                      ? theme.palette.mode === "dark"
                        ? "#38383E"
                        : "rgb(6,146,226,0.2)"
                      : "transparent",
                })}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip
                    title={item?.title}
                    componentsProps={{
                      tooltip: {
                        sx: (theme) => ({
                          backgroundColor: theme.palette.background.paper,
                          color: theme.palette.text.primary,
                        }),
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      data-tut={item?.reactTourId}
                    >
                      {item?.icon}
                    </ListItemIcon>
                  </Tooltip>

                  <ListItemText
                    primary={item?.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </StyledRouterLink>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default DashboardSidebar;
