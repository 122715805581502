export const getAccessToken = (key: string) => {
  return localStorage.getItem(key);
};

export const getTokens = () => {
  const accessToken = getAccessToken("accessToken");
  const refreshToken =   JSON.parse(localStorage.getItem("refreshToken"))?.token;

  return { accessToken, refreshToken };
}
